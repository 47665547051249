<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-form v-model="valid">
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title"
            >{{ method === "add" ? $t("create") : $t("edit") }}
            {{ $t("medical") }} {{ $t("organization") }}</span
          >
          <v-spacer />
          <v-icon small color="primary" @click="$emit('close')"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-card-text color="primary" class="mt-5">
          <span
            >{{ $t("welcomeToMedicalInstitutionRegistration") }}
            <span style="color: red">(required fields *)</span></span
          >
        </v-card-text>

        <v-row class="px-6">
          <v-col cols="12">
            <v-row dense cols="12" class="px-1 mb-4">
              <v-text-field
                v-model="selectedMedicalInstitution.institutionName"
                label="Institution Name *"
                prepend-inner-icon="mdi-office-building mr-1"
                dense
                :rules="rules"
                background-color="#E8E8E8"
                rounded
                outlined
                style="border-radius: 12px !important; height: 40px"
              ></v-text-field>
              <v-text-field
                v-model="selectedMedicalInstitution.contactPerson"
                label="Contact Person *"
                prepend-inner-icon="mdi-account mr-1"
                dense
                :rules="rules"
                background-color="#E8E8E8"
                rounded
                class="ml-3"
                outlined
                style="border-radius: 12px !important; height: 40px"
              ></v-text-field>
            </v-row>
            <v-row dense cols="12" class="px-1 mb-4">
              <v-col>
                <validation-provider name="Phone number" rules="required" v-slot="{ errors }">
                <v-text-field
                  ref="phoneNumber"
                  v-model="selectedMedicalInstitution.contactPhone"
                  validate-on-blur
                  label="Phone number *"
                  prepend-inner-icon="mdi-cellphone"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  class="ml-n1"
                  style="border-radius: 12px !important; height: 40px"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
              </v-col>
              <v-col>
                <validation-provider ref="email" :name="$t('email')" rules="required|email" v-slot="{ errors }" >
                <v-text-field
                  v-model="selectedMedicalInstitution.contactEmail"
                  label="Contact Email *"
                  prepend-inner-icon="mdi-email mr-1"
                  dense
                  :rules="rules"
                  background-color="#E8E8E8"
                  rounded
                  class="ml-1 mr-n1"
                  outlined
                  style="border-radius: 12px !important; height: 40px"
                  :error-messages="emailError = errors"
                ></v-text-field>
              </validation-provider>
              </v-col>
            </v-row>
            <v-text-field
              ref="address"
              v-model="selectedMedicalInstitution.address"
              @blur="locationDetails()"
              validate-on-blur
              label="Address, State, Postal Code, City, Country *"
              prepend-inner-icon="mdi-map-marker mr-1"
              dense
              :rules="rules"
              background-color="#E8E8E8"
              rounded
              outlined
              class="mb-5"
              placeholder="Address, State, Postal Code, City, Country"
              style="border-radius: 12px !important; height: 40px"
            ></v-text-field>
            <v-textarea
              v-model="selectedMedicalInstitution.description"
              label="Description *"
              prepend-inner-icon="mdi-card-text mr-1"
              dense
              :rules="rules"
              background-color="#E8E8E8"
              rounded
              outlined
              style="border-radius: 12px !important"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions class="px-6">
          <Alert class="mt-4" v-if="showErrors" :title="title" :type="alertType"></Alert>
          <v-spacer />
          <div class="my-3">
            <v-btn class="table-create-button" @click="$emit('close')">
              <v-icon class="icon-cancel">mdi-window-close</v-icon>
              <span>{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn
              class="table-create-button"
              @click="manageMedicalInstitution"
            >
              <v-icon class="icon">mdi-plus</v-icon>
              <span>{{ $t("save") }}</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <ResponseDialog v-if="responseStatus" :status.sync="responseStatus" :title="responseTitle" :color="responseColor"></ResponseDialog>
    </v-form>
  </v-dialog>
</template>
<script>
import { AsYouType } from "libphonenumber-js";
import ResponseDialog from "@/views/Client/components/Alert.vue";
import Alert from "@/views/Client/components/AlertDialog.vue";
import { phoneNumberFormatter } from '../../utils/luxon-formater'

export default {
  components: {
    Alert,
    ResponseDialog
  },
  props: ["dialog", "selectedMedicalInstitution", "method"],
  data() {
    return {
      responseStatus: false,
      responseTitle: "",
      responseColor: "",
      title: "Something went wrong",
      alertType: false,
      showErrors: false,
      validationErrors: [],
      valid: false,
      address: "",
      address_info: {
        address: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        lat: 0,
        lng: 0,
      },
      institutionName: "",
      contactPerson: "",
      contactEmail: "",
      description: "",
      contactPhone: "",
      phoneData: {
        phone: "",
        phoneCodeModel: "US 1",
      },
      emailError: false,
    };
  },
  computed: {
    rules() {
      return [(v) => !!v || ""];
    },
  },
  watch: {
    contactPhone(val) {
      if (val !== undefined) {
        this.contactPhone = new AsYouType().input(val);
      }
    },
  },
  mounted() {
    if (this.method !== "add") {
      this.locationDetails();
    }
  },
  methods: {
    async timeZone(lat, lng) {
      let object = {
        lat: lat,
        lng: lng,
      };
      const time_zone = await this.$store.dispatch(
        "googleAPI/getTimeZone",
        object
      );
      return time_zone;
    },
    async locationDetails() {
      if (this.selectedMedicalInstitution.address) {
        const geo_location = await this.$store.dispatch(
          "googleAPI/getGeoLocation",
          this.selectedMedicalInstitution.address
        );
        const address_components = geo_location.address_components;
        this.address_info.lat = geo_location.geometry.location.lat;
        this.address_info.lng = geo_location.geometry.location.lng;
        this.formattedAddress = geo_location.formatted_address;
        this.address_info.address = this.formattedAddress;
        address_components.forEach((ac) => {
          if (ac.types.includes("administrative_area_level_1")) {
            this.address_info.state = ac.long_name;
          }
          if (ac.types.includes("locality")) {
            this.address_info.city = ac.long_name;
          }
          if (ac.types.includes("country")) {
            this.address_info.country = ac.long_name;
          }
          if (ac.types.includes("postal_code")) {
            this.address_info.postal_code = ac.long_name;
          }
        });
        if (this.address_info.lat !== null) {
          const tz = await this.timeZone(
            this.address_info.lat,
            this.address_info.lng
          );
          if (tz) {
            this.timezone = tz.zoneName;
          }
        }
      }
    },
    checkValid () {
      this.validationErrors = []
      if (!phoneNumberFormatter(this.selectedMedicalInstitution.contactPhone)){
        this.validationErrors.push('phone')
      }
      if (this.emailError.length > 0) {
        this.validationErrors.push('email')
      }
      if(this.address_info.address === "") {
        this.validationErrors.push('address')
      }
      if (this.validationErrors.length > 0) {
        return false
      } else return true
    },
    async createMedicalInstitution(body) {
      await this.$store
        .dispatch("medicalInstitutions/createMedicalInstitution", body)
        .then((res) => {
          if (res) {
            this.responseColor = 'main_green'
            this.responseTitle = "Successfully created Medical Institution"
            this.responseStatus = true
            this.$emit("false");
          } else {
            this.responseColor = 'main_red'
            this.responseTitle = "Failed to create Medical Institution!"
            this.responseStatus = true
          }
        });
    },
    async editMedicalInstitution(body) {
      body.id = this.selectedMedicalInstitution.id;
      await this.$store
        .dispatch("medicalInstitutions/editMedicalInstitution", body)
        .then((res) => {
          if (res) {
            this.responseColor = 'main_green'
            this.responseTitle = "Successfully created Medical Institution"
            this.responseStatus = true
            this.$emit("false");
          } else {
            this.responseColor = 'main_red'
            this.responseTitle = "Failed to create Medical Institution!"
            this.responseStatus = true
          }
        });
    },
    async manageMedicalInstitution() {
      const valid = this.checkValid()
      if (!valid) {
        this.title = "Provided data is not valid: " + this.validationErrors.toString()
        this.alertType = 'error'
        this.showErrors = true
      }
      if (valid) {
        await this.locationDetails();
        const body = {
          institutionName: this.selectedMedicalInstitution.institutionName,
          description: this.selectedMedicalInstitution.description,
          contactPerson: this.selectedMedicalInstitution.contactPerson,
          contactPhone: this.selectedMedicalInstitution.contactPhone,
          contactEmail: this.selectedMedicalInstitution.contactEmail,
          address: this.address_info.address,
          zip: this.address_info.postal_code,
          city: this.address_info.city,
          state: this.address_info.state,
          country: this.address_info.country,
          addressLatitude: this.address_info.lat,
          addressLongitude: this.address_info.lng,
        };
        if (this.method === "add") {
          this.createMedicalInstitution(body);
        } else {
          this.editMedicalInstitution(body);
        }
      }
    },
  },
};
</script>