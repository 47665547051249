<template>
  <v-container fluid>
    <v-card flat style="border-radius: 12px">
      <v-card-title>
        <v-row>
          <v-col class="d-flex align-center justify-start" cols="8"
            ><h5 class="d-flex align-left">
              {{ $t("medical") }} {{ $t("organization") }}
            </h5>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search-organization')"
              single-line
              hide-details
              filled
              dense
              background-color="#E8E8E8"
              rounded
              class="table-search-button"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :headers-length="headers.length"
        :search="search"
        :items="medicalInstitutions"
        :items-per-page="medicalInstitutions.length"
        sort-by="id"
        item-key="id"
        fixed-header
        style="cursor: pointer"
        @click:row="medicalInstitutionDetails"
        :height="testHeight"
      >
        <template v-slot:[`footer.prepend`]="{}">
          <v-btn
            v-if="roleName === 'SYSTEM_ADMIN'"
            class="table-create-button"
            label="Test"
            @click="addMedicalInstitution"
          >
            <v-icon class="icon">mdi-plus</v-icon>
            <span>{{ $t("addMedicalInstitutionTooltip") }}</span>
          </v-btn>
        </template>
        <template v-slot:[`item.statusShortDescription`]="{ item }">
          <img :src="patient_icon(item.status)" height="24px" />
        </template>
      </v-data-table>
    </v-card>
    <AddMedicalInstitutionDialog
      v-if="dialog"
      :dialog="dialog"
      :method="method"
      :selectedMedicalInstitution="selectedMedicalInstitution"
      @false="closeDialog"
      @close="dialog = false"
    />
  </v-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AddMedicalInstitutionDialog from "../dialogs/AddMedicalInstitutionDialog.vue";

export default {
  components: {
    AddMedicalInstitutionDialog,
  },
  data: () => ({
    dialog: false,
    loading: true,
    medicalInstitutions: [],
    search: "",
    selectedMedicalInstitution: {},
    method: "",
    headerClass: "black_inner--text !important",
  }),
  computed: {
    ...mapGetters({ roleName: "authentication/getRole" }),
    ...mapState({
      pageSize: (state) => state.index.pageSize,
      medicalInstitutionsFromRepo: (state) =>
        state.medicalInstitutions.medicalInstitutions,
    }),
    testHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return window.innerHeight - 320;
        case "sm":
          return window.innerHeight - 300;
        case "md":
          return window.innerHeight - 300;
        case "lg":
          return window.innerHeight - 280;
        case "xl":
          return window.innerHeight - 280;
        default:
          return 0;
      }
    },
    headers() {
      var headers = [];
      headers = [
        {
          text: this.$t('header-institute-name'),
          value: "institutionName",
          class: this.headerClass,
        },
        {
          text: this.$t('header-contact-person'),
          value: "contactPerson",
          class: this.headerClass,
        },
        {
          text: this.$t('header-contact-phone'),
          value: "contactPhone",
          class: this.headerClass,
        },
        {
          text: this.$t('header-contact-email'),
          value: "contactEmail",
          class: this.headerClass,
        },
      ];
      return headers;
    },
    height() {
      return window.innerHeight - 250;
    },
  },
  async mounted() {
    await this.getMedicalInstitutions();
  },
  methods: {
    addMedicalInstitution() {
      this.method = "add";
      this.selectedMedicalInstitution = {};
      this.dialog = true;
    },
    editMedicalInstitution(item) {
      this.method = "edit";
      this.selectedMedicalInstitution = item;
      this.dialog = true;
    },
    async getMedicalInstitutions() {
      await this.$store
        .dispatch("medicalInstitutions/getMedicalInstitutions")
        .then(() => {
          this.medicalInstitutions = this.medicalInstitutionsFromRepo;
          this.loading = false;
        });
    },
    medicalInstitutionDetails(item) {
      this.editMedicalInstitution(item);

      // Do not delete this commented code until delete medical inst is implemented

      // this.$router.push({
      //   name: "Medical Organization Details",
      //   params: { id: `${item.id}` },
      // });
    },
    closeDialog() {
      this.dialog = false;
      this.getMedicalInstitutions();
    },
  },
};
</script>